<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="index-banner-pc">
        <div class="image-container">
          <img src="../assets/1.png" alt="" class="swiper" />
          <a
            class="clickable-area"
            href="http://www.hnpinxun.cn/#/"
            target="_blank"
          ></a>
        </div>
      </div>
      <div class="section_2 flex-col">
        <span class="text_12">为什么要做双报到</span>
        <div class="group_1 flex-row justify-between" id="group_1">
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="../assets/2.png"
            :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
          />
          <span
            class="paragraph_1"
            :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
            >&nbsp;为解决社区居民实际需求，营造社区互联互动、互帮互促的浓厚氛围，街道办大力创新工作举措，通过搭建“双报到”小程序，进一步强化对<br />“双报到”工作的统筹协调和指导督促，推动基层党组织和党员干部主<br />动服务社区，发挥实际作用，建立长效机制，确保服务成效。<br />&nbsp;&nbsp;&nbsp;&nbsp;<br />“双报到”小程序利用云计算、移动互联网、微信小程序等技术，实<br />现“双报到双积分”体系平台，功能涵盖“党员干部双报到、活动上报、<br />居民需求发布、社区资源共享、捐赠服务、积分反馈”等，打造“组织发动、党员带动、积分促动、反馈互动”的闭环运行体系。<br
          /></span>
        </div>
      </div>
      <div class="section_3 flex-col">
        <div class="text-group_1 flex-col justify-between">
          <span class="text_13">双报到功能</span>
          <span class="text_14"
            >实现需求与服务的精准对接，让“需求”有回应、让“服务”有价值</span
          >
        </div>
        <div class="box_5 flex-row justify-between">
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/3.png"
            />
            <div class="text-wrapper_4 flex-col justify-between">
              <span class="text_15">双报到组织服务</span>
              <span class="paragraph_8"
                >“双报到”包括组织报到和单位党员干部报到两个层面，根据服务不同，开展线上线下报到工作。</span
              >
            </div>
          </div>
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/4.png"
            />
            <div class="text-wrapper_5 flex-col justify-between">
              <span class="text_16">发布需求</span>
              <span class="paragraph_9"
                >社区辖区内居民、单位、组织都可通过“双报到”小程序发布实际需求，提交后由各社区平台管理员调查审核确认。</span
              >
            </div>
          </div>
        </div>
        <div class="box_6 flex-row justify-between">
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/5.png"
            />
            <div class="text-wrapper_6 flex-col justify-between">
              <span class="text_17">捐赠服务</span>
              <span class="paragraph_8"
                >社区单位、组织、个人可将一些闲置物品资源通过“双报到”小程序选择“我要捐赠”选项发布共享。</span
              >
            </div>
          </div>
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/6.png"
            />
            <div class="text-wrapper_7 flex-col justify-between">
              <span class="text_18">开展社区服务活动</span>
              <span class="paragraph_9"
                >社区办事处可通过“双报到”开展更多有意义的社区服务活动，通过筹需求、筹服务、筹资源、筹资金<br
              /></span>
            </div>
          </div>
        </div>
        <div class="box_7 flex-row justify-between">
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/7.png"
            />
            <div class="text-wrapper_8 flex-col justify-between">
              <span class="text_19">物业或业委会选举投票</span>
              <span class="paragraph_8"
                >平台用户可以对本小区物业或业委会选举进行投票，也可以对本小区物业或业委会的服务进行评价打分</span
              >
            </div>
          </div>
          <div class="block_1 flex-row">
            <div class="image-wrapper_6 flex-col">
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="../assets/8.png"
              />
            </div>
            <div class="text-wrapper_9 flex-col justify-between">
              <span class="text_20">积分管理</span>
              <span class="paragraph_9"
                >积分管理制度包括组织积分和个人积分，根据积分管理制度。对工作任务制定了详细的积分细则和计分办法</span
              >
            </div>
          </div>
        </div>
        <div class="box_8 flex-row justify-between">
          <div class="block_1 flex-row">
            <div class="image-wrapper_7 flex-col">
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="../assets/9.png"
              />
            </div>
            <div class="text-wrapper_10 flex-col justify-between">
              <span class="text_21">积分兑换</span>
              <span class="paragraph_8"
                >党员干部及个人获得的公益积分，均可在“双报到”的“爱心驿站
                ”和“秒杀活动”中兑换及抵现金使用</span
              >
            </div>
          </div>
          <div class="block_1 flex-row">
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="../assets/10.png"
            />
            <div class="text-wrapper_11 flex-col justify-between">
              <span class="text_22">积分排行榜</span>
              <span class="paragraph_9"
                >平台项目认领或者参与活动获得积分，可查看自己的积分排行、自己所在组织的排行以及所在社区的排行</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="section_6 flex-col">
        <div style="position: relative">
          <img src="../assets/11.png" alt="" class="section_img" />
          <a
            class="clickable-area-1"
            href="http://www.hnpinxun.cn/#/"
            target="_blank"
          ></a>
        </div>

        <span class="text_24">双报到特点</span>
        <span class="text_25"
          >形成上下联动、平行互动、横到边、纵贯底的闭环式服务模式，更大限度地激发了区域发展活力，实现了党建与发展的“1+1＞2”</span
        >
        <div class="block_4 flex-row">
          <div class="block_5 flex-col">
            <div class="image-wrapper_9 flex-col">
              <img
                class="image_16"
                referrerpolicy="no-referrer"
                src="../assets/13.png"
              />
            </div>
            <span class="text_26">易操作性</span>
            <span class="paragraph_11"
              >功能模块清晰<br />简单易上手<br />各模块角色职能清晰</span
            >
          </div>
          <div class="block_6 flex-col">
            <div class="image-wrapper_10 flex-col">
              <img
                class="image_17"
                referrerpolicy="no-referrer"
                src="../assets/15.png"
              />
            </div>
            <span class="text_27">易传播性</span>
            <span class="paragraph_12"
              >基于微信小程序开发，<br />可将服务活动快速分享<br />在相关群、朋友圈或者<br />微信好友</span
            >
          </div>
          <div class="block_7 flex-col">
            <div class="image-wrapper_11 flex-col">
              <img
                class="image_18"
                referrerpolicy="no-referrer"
                src="../assets/17.png"
              />
            </div>
            <span class="text_28">精准服务</span>
            <span class="paragraph_13"
              >需求清单和供给清单双向推动<br />实现需求与服务的精准对接<br />让“需求”有回应<br />让“服务”有价值</span
            >
          </div>
          <div class="block_8 flex-col">
            <div class="image-wrapper_12 flex-col">
              <img
                class="image_19"
                referrerpolicy="no-referrer"
                src="../assets/19.png"
              />
            </div>
            <span class="text_29">公益闭环引发1+1＞2效应</span>
            <span class="paragraph_14"
              >让党组织和两新组织“动”起来<br />让党员的技能“用”起来<br />让社区关系“和”起来<br />实现党建与发展的“1+1＞2”<br
            /></span>
          </div>
        </div>
        <span class="text_30">双报到案例</span>
        <div class="block_9 flex-row justify-between">
          <div class="list_1 flex-row">
            <div
              class="image-text_1 flex-col justify-between"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <img
                class="image_20"
                referrerpolicy="no-referrer"
                :src="item.lanhuimage"
              />
              <span class="text-group_2" v-html="item.lanhutext"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="section_32 flex-row">
        <div class="box_47 flex-col">
          <img
            class="image_42"
            referrerpolicy="no-referrer"
            src="../assets/35.png"
          />
          <span class="text_104">河南品讯网络科技有限公司</span>
          <span class="text_105">0379-61118888</span>
          <span class="paragraph_25"
            >地址：河南省洛阳市老城区青年创业大厦24层</span
          >
        </div>
        <div class="box_48 flex-col"></div>
        <div class="text-wrapper_38 flex-col">
          <span class="text_106">产品体系</span>
          <span
            class="text_107"
            @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
            >远程踏勘管理系统
          </span>
          <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
            >友推云·电商SaaS平台</span
          >
          <span
            class="text_107"
            @click="goOther('https://fastrms.hnpinxun.cn/')"
            >视频资源管理AI工具</span
          >
          <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
            >亥时学习平台</span
          >
          <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
            >文明积分银行</span
          >
          <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
            >邻里中心智慧平台</span
          >
        </div>
        <div class="text-wrapper_38 flex-col">
          <span class="text_106"></span>
          <span
            class="text_107"
            @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
            >工厂生产管理系统</span
          >
          <span
            class="text_107"
            @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
            >酒店系统</span
          >
          <span
            class="text_107"
            @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
            >党员双报到</span
          >
          <span
            class="text_107"
            @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
            >货车道路救援系统</span
          >
          <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
            >招商宣传</span
          >
          <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
            >智慧环卫综合平台</span
          >
        </div>
        <div class="text-wrapper_38 flex-col">
          <span class="text_106"></span>
          <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
            >家政行业公共服务平台</span
          >
        </div>

        <div class="box_49 flex-col"></div>
        <div class="box_50 flex-col justify-between">
          <img
            class="image_43"
            referrerpolicy="no-referrer"
            src="../assets/33.png"
          />
          <span class="text_120">微信小程序</span>
        </div>
        <div class="box_51 flex-col justify-between">
          <img
            class="image_44"
            referrerpolicy="no-referrer"
            src="../assets/34.png"
          />
          <span class="text_121">微信公众号</span>
        </div>
      </div>
      <div class="text-wrapper_16 flex-col">
        <span class="text_45"
          >Copyright&nbsp;©&nbsp;2020&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuimage: require("../assets/20.png"),
          lanhutext: "双报到系统",
        },
        {
          lanhuimage: require("../assets/21.png"),
          lanhutext: "党群e家服务平台",
        },
        {
          lanhuimage: require("../assets/22.png"),
          lanhutext: "双报到看明珠",
        },
        {
          lanhuimage: require("../assets/23.png"),
          lanhutext: "查看更多案例",
        },
      ],
      constants: {},
      OneShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>

<style scoped>
.page {
  position: relative;
  overflow: hidden;
}

.index-banner-pc {
  position: relative;
  height: 29vw;
}

.index-banner-pc .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.index-banner-pc .swiper {
  width: 100%;
  height: 100%;
  /* */
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.clickable-area {
  position: absolute;
  bottom: 5.3vw;
  left: 18.2vw;
  width: 160px;
  height: 60px;
  z-index: 2;
  cursor: pointer;
}

.section_2 {
  width: 100vw;
  height: 31.15vw;
}

.text_12 {
  width: 13.23vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 4.63vw 0 0 43.43vw;
}

.group_1 {
  width: 62.19vw;
  height: 15.63vw;
  margin: 5vw 0 4.21vw 18.75vw;
}

.image_7 {
  width: 25vw;
  height: 15.63vw;
}

.paragraph_1 {
  width: 26.1vw;
  height: 15vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.78vw;
  margin-top: 0.32vw;
}

.section_3 {
  background-color: rgba(246, 249, 254, 1);
  width: 100vw;
  height: 52.09vw;
  margin-top: -0.05vw;
}

.text-group_1 {
  width: 25vw;
  height: 3.39vw;
  margin: 4.16vw 0 0 37.08vw;
}

.text_13 {
  width: 8.34vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.78vw;
  margin-left: 8.81vw;
}

.text_14 {
  width: 25vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 1.46vw;
  margin-top: 0.89vw;
}

.box_5 {
  width: 62.5vw;
  height: 8.34vw;
  margin: 3.75vw 0 0 18.75vw;
}

.block_1 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  width: 30.73vw;
  height: 8.34vw;
}
.block_1:hover {
  transform: translateY(-10px);
}

.image_8 {
  height: 5.21vw;
  width: 5.21vw;
  margin: 1.56vw 0 0 1.3vw;
  /* margin: 1.25vw 0 0 1.56vw; */
}

.text-wrapper_4 {
  width: 20.27vw;
  height: 4.12vw;
  margin: 2.44vw 2.6vw 0 1.35vw;
}

.text_15 {
  width: 6.57vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 1.46vw;
  margin-left: 0.06vw;
}

.image-wrapper_2 {
  background-color: rgba(0, 194, 181, 1);
  border-radius: 50%;
  height: 5.21vw;
  width: 5.21vw;
  margin: 1.56vw 0 0 1.3vw;
}

.image_9 {
  width: 2.4vw;
  height: 2.71vw;
  margin: 1.25vw 0 0 1.4vw;
}

.text-wrapper_5 {
  width: 21.1vw;
  height: 4.17vw;
  margin: 2.39vw 1.77vw 0 1.35vw;
}

.text_16 {
  width: 3.75vw;
  height: 0.99vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.box_6 {
  width: 62.5vw;
  height: 8.34vw;
  margin: 1.04vw 0 0 18.75vw;
}

.group_2 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  width: 30.73vw;
  height: 8.34vw;
}

.image_10 {
  width: 2.56vw;
  height: 2.61vw;
  margin: 1.3vw 0 0 1.35vw;
}

.text-wrapper_6 {
  width: 20.84vw;
  height: 4.12vw;
  margin: 2.44vw 2.03vw 0 1.35vw;
}

.text_17 {
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.group_3 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  width: 30.73vw;
  height: 8.34vw;
}

.image_11 {
  width: 2.92vw;
  height: 2.56vw;
  margin: 1.35vw 0 0 1.14vw;
}

.text-wrapper_7 {
  width: 20.84vw;
  height: 4.12vw;
  margin: 2.44vw 2.03vw 0 1.35vw;
}

.text_18 {
  width: 7.5vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.box_7 {
  width: 62.5vw;
  height: 8.34vw;
  margin: 1.04vw 0 0 18.75vw;
}

.image_12 {
  width: 2.56vw;
  height: 2.61vw;
  margin: 1.3vw 0 0 1.35vw;
}

.text-wrapper_8 {
  width: 20.84vw;
  height: 4.12vw;
  margin: 2.44vw 2.03vw 0 1.35vw;
}

.text_19 {
  width: 9.38vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.section_5 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  width: 30.73vw;
  height: 8.34vw;
}

.image_13 {
  width: 2.61vw;
  height: 2.61vw;
  margin: 1.3vw 0 0 1.3vw;
}

.text-wrapper_9 {
  width: 20.79vw;
  height: 4.17vw;
  margin: 2.39vw 2.08vw 0 1.35vw;
}

.text_20 {
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.box_8 {
  width: 62.5vw;
  height: 8.34vw;
  margin: 1.04vw 0 4.32vw 18.75vw;
}

.image_14 {
  width: 2.61vw;
  height: 2.61vw;
  margin: 1.3vw 0 0 1.3vw;
}

.text-wrapper_10 {
  width: 19.95vw;
  height: 4.12vw;
  margin: 2.44vw 2.91vw 0 1.35vw;
}

.text_21 {
  width: 3.75vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.paragraph_8 {
  width: 19.95vw;
  /* height: 2.14vw; */
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.36vw;
  margin-top: 1.1vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.image_15 {
  width: 2.92vw;
  height: 2.4vw;
  margin: 1.4vw 0 0 1.14vw;
}

.text-wrapper_11 {
  width: 20.84vw;
  height: 4.12vw;
  margin: 2.44vw 2.03vw 0 1.35vw;
}

.text_22 {
  width: 4.69vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.46vw;
}

.paragraph_9 {
  width: 20.84vw;
  /* height: 2.19vw; */
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.36vw;
  margin-top: 1.05vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
}

.section_6 {
  width: 100vw;
  height: 74.66vw;
}
.section_img {
  height: 15.6vw;
}
.clickable-area-1 {
  position: absolute;
  bottom: 2vw;
  left: 45.26vw;
  width: 190px;
  height: 60px;
  z-index: 2;
  /* border: 1px solid; */
  /* opacity: 0; */
  /* pointer-events: none; */
  cursor: pointer;
}

.text_24 {
  width: 8.34vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 3.13vw;
  margin: 4.21vw 0 0 45.88vw;
}

.text_25 {
  width: 47.35vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 3.13vw;
  margin: 1.82vw 0 0 26.3vw;
}

.block_4 {
  width: 62.45vw;
  height: 21.57vw;
  margin: 3.54vw 0 0 18.8vw;
}

.block_5 {
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.57vw;
}
.block_5:hover {
  transform: translateY(-10px);
}

.image-wrapper_9 {
  height: 8.13vw;
  background: url("/src/assets/12.png") 100% no-repeat;
  background-size: 100% 100%;
  margin-left: -0.05vw;
  width: 14.85vw;
}

.image_16 {
  width: 2.97vw;
  height: 3.86vw;
  margin: 2.13vw 0 0 5.93vw;
}

.text_26 {
  width: 4.17vw;
  height: 0.99vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: justifyLeft;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 2.03vw 0 0 5.36vw;
}

.paragraph_11 {
  width: 7.5vw;
  height: 4.54vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  line-height: 1.88vw;
  margin: 1.56vw 0 4.32vw 3.69vw;
}

.block_6 {
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.57vw;
  margin-left: 0.99vw;
}
.block_6:hover {
  transform: translateY(-10px);
}

.image-wrapper_10 {
  height: 8.13vw;
  background: url("/src/assets/14.png") 100% no-repeat;
  background-size: 100% 100%;
  width: 14.85vw;
}

.image_17 {
  width: 3.44vw;
  height: 3.44vw;
  margin: 2.34vw 0 0 5.72vw;
}

.text_27 {
  width: 4.17vw;
  height: 0.99vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: justifyLeft;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 2.03vw 0 0 5.36vw;
}

.paragraph_12 {
  width: 8.34vw;
  height: 6.41vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  line-height: 1.88vw;
  margin: 1.56vw 0 2.44vw 3.28vw;
}

.block_7 {
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.57vw;
  margin-left: 1.05vw;
}
.block_7:hover {
  transform: translateY(-10px);
}

.image-wrapper_11 {
  height: 8.13vw;
  background: url("/src/assets/16.png") 100% no-repeat;
  background-size: 100% 100%;
  width: 14.85vw;
}

.image_18 {
  width: 3.55vw;
  height: 3.44vw;
  margin: 2.34vw 0 0 5.67vw;
}

.text_28 {
  width: 4.17vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: justifyLeft;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 2.03vw 0 0 5.36vw;
}

.paragraph_13 {
  width: 10.84vw;
  height: 6.46vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  line-height: 1.88vw;
  margin: 1.51vw 0 2.39vw 2.03vw;
}

.block_8 {
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 21.57vw;
  margin-left: 1.05vw;
}
.block_8:hover {
  transform: translateY(-10px);
}

.image-wrapper_12 {
  height: 8.13vw;
  background: url("/src/assets/18.png") 100% no-repeat;
  background-size: 100% 100%;
  width: 14.85vw;
}

.image_19 {
  width: 3.96vw;
  height: 3.13vw;
  margin: 2.5vw 0 0 5.46vw;
}

.text_29 {
  width: 12.04vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: justifyLeft;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 2.03vw 0 0 1.35vw;
}

.paragraph_14 {
  width: 11.67vw;
  height: 6.46vw;
  overflow-wrap: break-word;
  color: rgba(61, 61, 61, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  line-height: 1.88vw;
  margin: 1.51vw 0 2.39vw 1.61vw;
}

.text_30 {
  width: 8.29vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 1.66vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 1.78vw;
  margin: 4.16vw 0 0 45.78vw;
}

.block_9 {
  width: 62.5vw;
  height: 11.41vw;
  margin: 3.43vw 0 0 18.75vw;
}

.list_1 {
  width: 44.12vw;
  height: 11.25vw;
  justify-content: space-between;
}

.image-text_1 {
  width: 9.38vw;
  height: 11.05vw;
  margin: 0.15vw 7.96vw 0 0;
}

.image_20 {
  width: 9.38vw;
  height: 9.38vw;
}
.image_20:hover {
  transform: scale(1.1);
}

.text-group_2 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 2.61vw;
  margin: 0.93vw 0 0 0vw;
}

/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.text-wrapper_16 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  width: 100vw;
}

.text_45 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
</style>
